<template>
  <div>
    <vs-table search stripe border description :sst="true" :data="listData" :max-items="table.length"
      :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div class="
                p-3
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              ">
            <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div class="btn-group ml-5">
          <vs-button size="small" color="success" type="border" @click="handleExport('excel')">Excel</vs-button>
        </div>
      </template>
      <template slot="thead">
        <vs-th sort-key="shipment_date">Shipment Date</vs-th>
        <vs-th sort-key="shipment_no">Shipment No</vs-th>
        <vs-th sort-key="status_ship">Shipment Status</vs-th>
        <vs-th sort-key="jml_do">Jml. DO</vs-th>
        <vs-th sort-key="jml_customer">Jml. Customer</vs-th>
        <vs-th sort-key="shipment_type">Shipment Type</vs-th>
        <vs-th sort-key="ship_type_descr">Ship. Type Descr</vs-th>
        <vs-th sort-key="trans_plan_point">Trans. Plan. Point</vs-th>
        <vs-th sort-key="driver_name">Driver Name</vs-th>
        <vs-th sort-key="vehicle_no">Vehicle No.</vs-th>
        <vs-th sort-key="shipping_type">Shipping Type</vs-th>
        <vs-th sort-key="fwd_agent_no">Fwd. Agent No.</vs-th>
        <vs-th sort-key="fwd_agent_name">Fwd Agent Name</vs-th>
        <vs-th sort-key="km_berangkat">KM Berangkat</vs-th>
        <vs-th sort-key="km_kembali">KM Kembali</vs-th>
        <vs-th sort-key="distance">Distance</vs-th>
        <vs-th sort-key="distance_uom">Distance UOM</vs-th>
        <vs-th sort-key="shipment_cost_no">Shipment Cost No.</vs-th>
        <vs-th sort-key="shipment_cost_value">Shipment Cost Value</vs-th>
        <vs-th sort-key="biaya_kuli">Biaya Kuli</vs-th>
        <vs-th sort-key="biaya_parkir">Biaya Parkir</vs-th>
        <vs-th sort-key="biaya_keamanan">Biaya Keamanan</vs-th>
        <vs-th sort-key="retribusi">Retribusi </vs-th>
        <vs-th sort-key="toll">Toll</vs-th>
        <vs-th sort-key="bbm">BBM</vs-th>
        <vs-th sort-key="others_mel">Others(Mel)</vs-th>
        <vs-th sort-key="nilai_realisasi_shipment_cost">Nilai Realisasi Shipment Cost</vs-th>
        <vs-th sort-key="currency">Currency</vs-th>
        <vs-th sort-key="status_realisasi_shipment_cost"> Status Realisasi Shipment Cost </vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].shipment_date">
            {{ dateFormat(data[indextr].shipment_date) }}
          </vs-td>
          <vs-td :data="data[indextr].shipment_no">
            {{ data[indextr].shipment_no }}
          </vs-td>
          <vs-td :data="data[indextr].status_ship">
            {{ data[indextr].status_ship }}
          </vs-td>
          <vs-td :data="data[indextr].jml_do">
            {{ data[indextr].jml_do }}
          </vs-td>
          <vs-td :data="data[indextr].jml_customer">
            {{ data[indextr].jml_customer }}
          </vs-td>
          <vs-td :data="data[indextr].shipment_type">
            {{ data[indextr].shipment_type }}
          </vs-td>
          <vs-td :data="data[indextr].ship_type_descr">
            {{ data[indextr].ship_type_descr }}
          </vs-td>
          <vs-td :data="data[indextr].trans_plan_point">
            {{ data[indextr].trans_plan_point }}
          </vs-td>
          <vs-td :data="data[indextr].driver_name">
            {{ data[indextr].driver_name }}
          </vs-td>
          <vs-td :data="data[indextr].vehicle_no">
            {{ data[indextr].vehicle_no }}
          </vs-td>
          <vs-td :data="data[indextr].shipping_type">
            {{ data[indextr].shipping_type }}
          </vs-td>
          <vs-td :data="data[indextr].fwd_agent_no">
            {{ data[indextr].fwd_agent_no }}
          </vs-td>
          <vs-td :data="data[indextr].fwd_agent_name">
            {{ data[indextr].fwd_agent_name }}
          </vs-td>
          <vs-td :data="data[indextr].km_berangkat">
            {{ data[indextr].km_berangkat }}
          </vs-td>
          <vs-td :data="data[indextr].km_kembali">
            {{ data[indextr].km_kembali }}
          </vs-td>
          <vs-td :data="data[indextr].distance">
            {{ data[indextr].distance }}
          </vs-td>
          <vs-td :data="data[indextr].distance_uom">
            {{ data[indextr].distance_uom }}
          </vs-td>
          <vs-td :data="data[indextr].shipment_cost_no">
            {{ data[indextr].shipment_cost_no }}
          </vs-td>
          <vs-td :data="data[indextr].shipment_cost_value">
            {{ data[indextr].shipment_cost_value }}
          </vs-td>
          <vs-td :data="data[indextr].biaya_kuli">
            {{ data[indextr].biaya_kuli }}
          </vs-td>
          <vs-td :data="data[indextr].biaya_parkir">
            {{ data[indextr].biaya_parkir }}
          </vs-td>
          <vs-td :data="data[indextr].biaya_keamanan">
            {{ data[indextr].biaya_keamanan }}
          </vs-td>
          <vs-td :data="data[indextr].retribusi">
            {{ data[indextr].retribusi }}
          </vs-td>
          <vs-td :data="data[indextr].toll">
            {{ data[indextr].toll }}
          </vs-td>
          <vs-td :data="data[indextr].bbm">
            {{ data[indextr].bbm }}
          </vs-td>
          <vs-td :data="data[indextr].others_mel">
            {{ data[indextr].others_mel }}
          </vs-td>
          <vs-td :data="data[indextr].nilai_realisasi_shipment_cost">
            {{ data[indextr].nilai_realisasi_shipment_cost }}
          </vs-td>
          <vs-td :data="data[indextr].currency">
            {{ data[indextr].currency }}
          </vs-td>
          <vs-td :data="data[indextr].status_realisasi_shipment_cost">
            {{ data[indextr].status_realisasi_shipment_cost }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination style="padding-top: 5px" :total="this.totalPage" v-model="setPage" />
  </div>
</template>
<script>
import moment from "moment";
import { mapState, mapActions } from "vuex/dist/vuex.common.js";
export default {
  components: {},
  props: {
    territoryIDs: {
      type: Array,
    },
    shipmentNoIDs: {
      type: Array,
    },
    piNoIDs: {
      type: Array,
    },
    shipmentCostNoIDs: {
      type: Array,
    },
    fwdAgentNoIDs: {
      type: Array,
    },
    startDate: {
      type: Date,
    },
    endDate: {
      type: Date,
    },
    draw: {
      type: Number,
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
      table_amount_header: "<  16 days"
    };
  },
  methods: {
    ...mapActions({
      getShipmentPlanningReport: 'reportShipmentPlanning/getShipmentPlanningReport',
      generateShipmentPlanningReport: 'reportShipmentPlanning/generateShipmentPlanningReport',
    }),
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      if (this.draw > 0) {
        this.$vs.loading();
        this.getShipmentPlanningReport({
          length: this.table.length,
          page: this.table.page,
          search: this.table.search,
          order: this.table.order,
          sort: this.table.sort,
          territory: this.territoryIDs,
          shipment_no: this.shipmentNoIDs,
          pi_no: this.piNoIDs,
          shipment_cost_no: this.shipmentCostNoIDs,
          fwd_agent_no: this.fwdAgentNoIDs,
          start_shipment_plan_date: this.startDate == null
            ? undefined
            : moment(this.startDate).format('YYYY-MM-DD'),
          end_shipment_plan_date: this.endDate == null
            ? undefined
            : moment(this.endDate).format('YYYY-MM-DD')
        })
          .then((resp) => {
            this.$vs.loading.close();
          });
      }
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      if (val) {
        return moment(val).format("DD MMM YYYY");
      }

      return '-'
    },
    handleExport(file) {
      this.$vs.loading();

      this.generateShipmentPlanningReport(
        {
          length: this.table.length,
          page: this.table.page,
          search: this.table.search,
          order: this.table.order,
          sort: this.table.sort,
          territory: this.territoryIDs,
          shipment_no: this.shipmentNoIDs,
          pi_no: this.piNoIDs,
          shipment_cost_no: this.shipmentCostNoIDs,
          fwd_agent_no: this.fwdAgentNoIDs,
          start_shipment_plan_date: this.startDate == null
            ? undefined
            : moment(this.startDate).format('YYYY-MM-DD'),
          end_shipment_plan_date: this.endDate == null
            ? undefined
            : moment(this.endDate).format('YYYY-MM-DD')
        }
      ).then(() => {
        this.$vs.loading.close();
      })
    },
    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    format(head, value) {
      if (typeof head["format"] === "function") {
        var f = head["format"];
        return f(value);
      }
      return value;
    },
  },
  watch: {
    draw() {
      this.getData();
    },
    listData(val) {
      this.table.start = ((this.table.page - 1) * this.table.length) + 1
      this.table.end = ((this.table.page - 1) * this.table.length) + val.length
    },
    totalRecord(val) {
      this.table.total = val
    },
    generateReport(val) {
      if (val && val.status === 'OK') {
        this.$vs.notify({
          color: "success",
          title: "Processing",
          text: val.message,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: val.message,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    }
  },
  computed: {
    ...mapState({
      listData: (state) => state.reportShipmentPlanning.tableData,
      totalPage: (state) => state.reportShipmentPlanning.totalPage,
      totalRecord: (state) => state.reportShipmentPlanning.total,
      generateReport: (state) => state.reportShipmentPlanning.generateReport,
    }),
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>